import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"


const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "goldfishEcoPond" } } }
        }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`

export default function NewInstallationsPage() {
  const title = `Goldfish Ponds & Water Garden` // Singular (not plural)
  const {
    allContentfulInstallationImages: { nodes: image },
  } = useStaticQuery(query)

  const imageList = image[0].image


  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`We can design and install ${title}s to transform your backyard into an oasis. Let us help you decide on the right size and style.`}
      />

      <ServicePage
        imageList={imageList}
        title={title}
        img={
          <StaticImage
            src="../../assets/images/10-Andreatta-Waterscapes-Natural-Pond-Central-Point-Oregon.jpg"
            quality={90}
            loading="eager"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              height: "21rem",
            }}
            objectFit="cover"
            objectPosition="50% 25%"
            alt="Natural Pond Central Point Oregon by Andreatta Waterscapes"
          />
        }
        tagline={`Bring a sense of peace and tranquility`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `If you’ve been dreaming about building a fish pond in your backyard, a goldfish pond could be a great place to start. We can help you decide on the right size and style for your yard.`,
          },
          {
            id: 1,
            type: "paragraph",
            content: `Goldfish ponds, water gardens and ecosystem ponds are a few of the terms that you may have heard for these types of backyard water features. Along with the soothing sound of running water, the addition of goldfish and aquatic plants create a thriving ecosystem and water garden in your yard. There are a host of fun plants that grow in aquatic environments, and if you are a gardener, a pond is a fun new landscape area to tend to.`,
          },
          {
            id: 2,
            type: "paragraph",
            content: `Having a water feature in your yard attracts a large variety of wildlife. Some of the creatures that visit and make their homes in and around your pond include birds, dragon flies, and water skippers. We guarantee you’ll start to see wildlife as soon as your water feature is installed… once we build it, they will come!`,
          },
        ]}
      />
    </Layout>
  )
}
